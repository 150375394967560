import { isNil, chunk } from "lodash"
import { makeAutoObservable, runInAction, toJS } from "mobx"
import { fetchRechargeProducts } from "../gatsby/fetchGastsbyData"
import { applyRechargeDiscount } from "../rechargeUtil"
import createShopifyClient from "../shopify/createShopifyClient"

export default class ProductStore {
  productAvailability = new Map()

  rechargeProducts = []

  constructor(rootStore) {
    this.rootStore = rootStore

    makeAutoObservable(this, {
      getPrice: false,
      isProductAvailable: false,
      rootStore: false,
    })
  }

  getPrice(price, productId, subscriptionType) {
    if (isNil(price)) {
      return price
    }

    const rechargeProduct =
      subscriptionType === "subscription" &&
      this.rechargeProducts.find(p => p.shopifyProductId === productId)

    if (rechargeProduct) {
      return applyRechargeDiscount(price, rechargeProduct)
    } else {
      return Number(price)
    }
  }

  async hydrateClient() {
    const rechargeProducts = await fetchRechargeProducts()

    runInAction(() => {
      this.rechargeProducts = rechargeProducts
    })
  }

  /**
   * Returns boolean indicating if product is available. `loadProductAvailability` must be called
   * first. `true` will be returned if the availability is currently being loaded.
   */
  isProductAvailable(gid) {
    return this.productAvailability.get(gid) !== false
  }

  /**
   * Loads product availability for the specified GIDs using the Shopify Storefront API. Results
   * will be cached for lifespan of this store. Calling this method again with the same GIDs will
   * not result in additional requests.
   */
  async loadProductAvailability(gids) {
    gids = gids.filter(gid => !this.productAvailability.has(gid))

    // store key in map with undefined value to indicate loading
    gids.forEach(gid => {
      this.productAvailability.set(gid)
    })

    const client = await createShopifyClient()

    for (const gidChunk of chunk(gids, 200)) {
      const resp = await client.product.fetchMultiple(gidChunk)

      runInAction(() => {
        resp.data.nodes.forEach(({ availableForSale, id }) => {
          this.productAvailability.set(atob(id), availableForSale)
        })
      })
    }
  }

  toJS() {
    const { rootStore, ...others } = this
    return toJS(others)
  }
}
