import CheckoutStore from "./CheckoutStore"
import CustomerStore from "./CustomerStore"
import ProductStore from "./ProductStore"
import FlashStore from "./ui/FlashStore"
import ModalStore from "./ui/ModalStore"
import OverlayStore from "./ui/OverlayStore"

export default class RootStore {
  constructor() {
    this.checkoutStore = new CheckoutStore(this)
    this.customerStore = new CustomerStore(this)
    this.flashStore = new FlashStore(this)
    this.modalStore = new ModalStore(this)
    this.overlayStore = new OverlayStore(this)
    this.productStore = new ProductStore(this)
  }

  /**
   * Hydrates the store using keys from browser's localStorage, cookies, etc. Don't call this
   * server-side.
   */
  async hydrateClient() {
    await Promise.all([
      this.checkoutStore.hydrateClient(),
      this.customerStore.hydrateClient(),
      this.flashStore.hydrateClient(),
      this.productStore.hydrateClient(),
    ])
  }

  toJS() {
    return {
      checkoutStore: this.checkoutStore.toJS(),
      customerStore: this.customerStore.toJS(),
      flashStore: this.flashStore.toJS(),
      overlayStore: this.overlayStore.toJS(),
      productStore: this.productStore.toJS(),
    }
  }
}
