let createClientPromise = null

const createBuyClient = () => {
  if (!createClientPromise) {
    createClientPromise = import(
      "shopify-buy" /* webpackChunkName: "shopifyBuySDK" */
    ).then(({ default: Client }) =>
      Client.buildClient({
        domain: process.env.GATSBY_SHOPIFY_DOMAIN,
        storefrontAccessToken:
          process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
      })
    )
  }

  return createClientPromise
}

export default createBuyClient
