import createShopifyClient from "../shopify/createShopifyClient"
import { makeAutoObservable, runInAction, toJS } from "mobx"
import { trackVisitor } from "../tracking/tracking"

const getPersistedCustomerToken = () =>
  localStorage.getItem("hoaShopifyCustomerToken")

const persistCustomerToken = token => {
  if (token) {
    localStorage.setItem("hoaShopifyCustomerToken", token)
  } else {
    localStorage.removeItem("hoaShopifyCustomerToken")
  }
}

const shopifyExceptionToUserError = error =>
  error?.graphQLErrors?.[0]?.message ||
  "An error occurred. Please try again later."

const fetchCustomer = async token => {
  if (token) {
    const client = await createShopifyClient()
    return (await client.customer.fetch(token)).data.customer
  } else {
    return null
  }
}

export default class CustomerStore {
  // `undefined` indicates not hydrated; `null` indicates hydrated with no customer/token
  customer
  token

  constructor(rootStore) {
    this.rootStore = rootStore

    makeAutoObservable(this, { rootStore: false })
  }

  async hydrateClient() {
    const token = getPersistedCustomerToken()
    const customer = await fetchCustomer(token)

    if (customer) {
      runInAction(() => {
        this.customer = customer
        this.token = token
      })

      trackVisitor(customer)
    } else {
      runInAction(() => {
        this.customer = null
        this.token = null
      })

      persistCustomerToken(null)
      trackVisitor()
    }
  }

  async activateByUrl({ activationUrl, password }) {
    const client = await createShopifyClient()
    const resp = await client.customer.activateByUrl({
      activationUrl,
      password,
    })

    const { customer, customerAccessToken, customerUserErrors } =
      resp.data.customerActivateByUrl

    if (customerUserErrors.length === 0) {
      this._login({ customer, customerAccessToken })
    } else {
      return customerUserErrors[0].message
    }
  }

  async createCustomer(input) {
    const client = await createShopifyClient()

    try {
      const resp = await client.customer.create(input)

      const { customerUserErrors } = resp.data.customerCreate

      if (customerUserErrors.length === 0) {
        return await this.login(input)
      } else {
        return customerUserErrors[0].message
      }
    } catch (error) {
      return shopifyExceptionToUserError(error)
    }
  }

  /**
   * Signs in a user. Returns a falsy value upon success or a user readable error message.
   */
  async login({ email, password }) {
    const client = await createShopifyClient()

    try {
      const resp = await client.customer.accessTokenCreate({ email, password })

      const { customerAccessToken, customerUserErrors } =
        resp.data.customerAccessTokenCreate

      if (customerUserErrors.length === 0) {
        const customer = (
          await client.customer.fetch(customerAccessToken.accessToken)
        ).data.customer

        this._login({ customer, customerAccessToken })

        return null
      } else {
        switch (customerUserErrors[0].message) {
          // value returned by Shopify when email is correct but password is incorrect
          case "Unidentified customer":
            return "Invalid email or password"

          default:
            return customerUserErrors[0].message
        }
      }
    } catch (error) {
      // Exception includes messages like, "Login attempt limit exceeded. Please try again later."
      return shopifyExceptionToUserError(error)
    }
  }

  async logout() {
    const token = this.token

    persistCustomerToken(null)
    this.token = null
    this.customer = null

    if (token) {
      const client = await createShopifyClient()

      try {
        await Promise.all([
          client.customer.accessTokenDelete(token),
          this.rootStore.checkoutStore.customerDisassociate(),
        ])
      } catch (error) {
        // accessTokenDelete throws if token is invalid; we can ignore
        console.error("accessTokenDelete: ", error)
      }
    }
  }

  /**
   * Sends password reset email. Returns falsy value upon success or a user readable error message.
   */
  async recover(email) {
    const client = await createShopifyClient()

    try {
      const resp = await client.customer.recover(email)
      return resp.data.customerRecover.customerUserErrors[0]?.message
    } catch (error) {
      // Exception includes messages like, "Resetting password limit exceeded. Please try again later."
      return shopifyExceptionToUserError(error)
    }
  }

  /**
   * Resets password. Returns falsy value upon success or a user readable error message.
   */
  async resetByUrl({ password, resetUrl }) {
    const client = await createShopifyClient()
    const resp = await client.customer.resetByUrl({ password, resetUrl })

    const { customer, customerAccessToken, customerUserErrors } =
      resp.data.customerResetByUrl

    if (customerUserErrors.length === 0) {
      this._login({ customer, customerAccessToken })
    } else {
      switch (customerUserErrors[0].message) {
        case "Invalid reset url":
          return "Reset code is invalid or has already been used"

        default:
          return customerUserErrors[0].message
      }
    }
  }

  _login({ customer, customerAccessToken }) {
    const token = customerAccessToken.accessToken

    persistCustomerToken(token)

    // intentionally not awaiting response
    this.rootStore.checkoutStore.customerAssociate(token)

    runInAction(() => {
      this.customer = customer
      this.token = token
    })
  }

  toJS() {
    const { rootStore, ...others } = this
    return toJS(others)
  }
}
