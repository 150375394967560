import { capitalize } from "lodash"
import pluralize from "../pluralize"

const subCadence = item => {
  const frequency = Number(getCartItemAttribute(item, "intervalFrequency"))
  const unit = getCartItemAttribute(item, "intervalUnit")

  return `Every ${frequency} ${capitalize(pluralize(frequency, unit))}`
}

export const cadenceDescription = item => {
  switch (getCartItemAttribute(item, "rechargeType")) {
    case "subscription":
      return subCadence(item)

    case "one-time":
      return "One Time Purchase"

    default:
      return null
  }
}

// Legacy variant naming includes the word "Capsules":
// https://app.asana.com/0/1200299136882357/1200337368575003/f
export const filterVariantOptions = options =>
  options.filter(
    ({ name, value }) => value !== "Default Title" && name !== "Capsules"
  )

export const getCartItemAttribute = ({ customAttributes }, key) =>
  customAttributes.find(attr => attr.key === key)?.value

export const isSubscription = item =>
  getCartItemAttribute(item, "rechargeType") === "subscription"
