// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/

import "./src/css/fonts/libre-franklin.css"
import "./src/css/global.css"
import "./src/css/shared-components.css"

import createWrapRootElement from "./src/lib/gatsby/createWrapRootElement"
import RootStore from "./src/lib/store/RootStore"

// https://www.npmjs.com/package/gatsby depends upon their fork of reach-router, so we should do
// the same to avoid loading duplicate routers
import { globalHistory } from "@gatsbyjs/reach-router"

const store = new RootStore()

// uncomment to make store available in the Javascript console
// window.store = store

globalHistory.listen(() => {
  // Hide cart when navigating. It's awkward when the back button is clicked on a mobile browser
  // and the side cart stays open.
  store.overlayStore.hide()

  // document.body.setAttribute("class", window.location.pathname)
})

window.addEventListener("pageshow", event => {
  if (event.persisted) {
    // Fix bug when checkout is visited in Safari then the back button is pressed. See
    // https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
    store.checkoutStore.clearIsCheckingOut()
  }
})

export const onInitialClientRender = () => {
  store.hydrateClient()
}

window.__hoaGatsbyScrollEnabled = true

export const shouldUpdateScroll = () => {
  return window.__hoaGatsbyScrollEnabled
}

export const wrapRootElement = createWrapRootElement(store)
