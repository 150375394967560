import createBuyClient from "../shopify/createBuyClient"
import { makeAutoObservable, runInAction, toJS } from "mobx"
import sum from "../sum"
import createShopifyClient from "../shopify/createShopifyClient"
import { toLegacyId } from "../shopify/shopifyUtil"
import { getCartItemAttribute, isSubscription } from "../shopify/cartUtil"
import { createCheckout } from "../api/checkoutApi"
import { trackAddToCart } from "../tracking/tracking"
import getGALinkerParam from "../tracking/getGALinkerParam"

const getPersistedCheckoutId = () =>
  localStorage.getItem("hoaShopifyCheckoutId")

const persistCheckoutId = id => {
  if (id) {
    localStorage.setItem("hoaShopifyCheckoutId", id)
  } else {
    localStorage.removeItem("hoaShopifyCheckoutId")
  }
}

export default class CheckoutStore {
  // Shopify checkout as returned by the Buy SDK or null
  checkout = null
  isCheckingOut = false
  isLoading = false

  constructor(rootStore) {
    this.rootStore = rootStore

    makeAutoObservable(this, { rootStore: false })
  }

  get itemCount() {
    if (!this.checkout) {
      return 0
    }

    return sum(this.checkout.lineItems, ({ quantity }) => quantity)
  }

  /**
   * Adds line items.
   *
   * @param {*} payload Single line item or list of line items. Will be passed directly to the
   * Shopify Buy SDK. See https://github.com/Shopify/js-buy-sdk#adding-line-items
   */
  async addLineItems(payload) {
    const result = await this._updateCheckout(
      async ({ buyClient }) =>
        await buyClient.checkout.addLineItems(this.checkout.id, payload)
    )

    trackAddToCart(result)

    return result
  }

  async addToCart(variantId, subscriptionType) {
    return await this.addLineItems({
      customAttributes: this._customAttributesForType(subscriptionType),
      quantity: 1,
      variantId: btoa(variantId),
    })
  }

  _customAttributesForType(subscriptionType) {
    switch (subscriptionType) {
      case "one-time":
        return [{ key: "rechargeType", value: "one-time" }]

      case "subscription":
        return [
          { key: "intervalFrequency", value: "30" },
          { key: "intervalUnit", value: "day" },
          { key: "rechargeType", value: "subscription" },
        ]
      default:
        return []
    }
  }

  clearIsCheckingOut() {
    this.isCheckingOut = false
  }

  /**
   * Associates a customer with the current checkout. This doesn't update this.checkout because this
   * doesn't change the checkout in a way that is meaningful to our app.
   */
  async customerAssociate(customerAccessToken) {
    if (this.checkout) {
      const client = await createShopifyClient()
      const resp = await client.checkout.customerAssociateV2({
        checkoutId: this.checkout.id,
        customerAccessToken,
      })

      if (
        resp.data.checkoutCustomerAssociateV2.checkoutUserErrors.length !== 0
      ) {
        throw new Error(`customerAssociate: ${JSON.stringify(resp)}`)
      }
    }
  }

  /**
   * Disassociates any customer that is associated with the current checkout. This doesn't update
   * this.checkout because this doesn't change the checkout in a way that is meaningful to our
   * app.
   */
  async customerDisassociate() {
    if (this.checkout) {
      const client = await createShopifyClient()
      const resp = await client.checkout.customerDisassociateV2({
        checkoutId: this.checkout.id,
      })

      if (
        resp.data.checkoutCustomerDisassociateV2.checkoutUserErrors.length !== 0
      ) {
        throw new Error(`customerDisassociate: ${JSON.stringify(resp)}`)
      }
    }
  }

  get hasSubscriptionItems() {
    if (!this.checkout) {
      return false
    }

    return this.checkout.lineItems.some(isSubscription)
  }

  async hydrateClient() {
    const checkoutId = getPersistedCheckoutId()

    if (!checkoutId) {
      return
    }

    runInAction(() => {
      this.isLoading = true
    })

    try {
      const buyClient = await createBuyClient()
      const checkout = await buyClient.checkout.fetch(checkoutId)

      if (checkout && !checkout.order?.orderNumber) {
        runInAction(() => {
          this.checkout = checkout
        })

        this._clearItemsWithNullVariants()
      } else {
        // Checkout will be null if ID has valid format but cannot be found. If the format is
        // invalid then an exception will be thrown.
        console.warn("clearing invalid or completed checkout from localstorage")
        persistCheckoutId(null)
      }
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  async navigateToCheckout() {
    this.isCheckingOut = true

    if (this.hasSubscriptionItems) {
      const { checkout_url } = await createCheckout(this.checkout)

      window.location.href = `${checkout_url}?${getGALinkerParam()}`
    } else {
      window.location.href = `${this.checkout.webUrl}&${getGALinkerParam()}`
    }
  }

  /**
   * https://github.com/Shopify/js-buy-sdk#removing-line-items
   */
  async removeLineItems(lineItemIds) {
    return await this._updateCheckout(
      async ({ buyClient }) =>
        await buyClient.checkout.removeLineItems(this.checkout.id, lineItemIds)
    )
  }

  /**
   * Returns subtotal, taking into account discounted pricing for subscriptions.
   */
  get subtotal() {
    if (!this.checkout) {
      return 0
    }

    return sum(
      this.checkout.lineItems,
      item =>
        this.rootStore.productStore.getPrice(
          item.variant.price,
          toLegacyId(item.variant.product.id),
          getCartItemAttribute(item, "rechargeType")
        ) * item.quantity
    )
  }

  /**
   * https://github.com/Shopify/js-buy-sdk#updating-line-items
   */
  async updateLineItems(updates) {
    return await this._updateCheckout(
      async ({ buyClient }) =>
        await buyClient.checkout.updateLineItems(this.checkout.id, updates)
    )
  }

  // When a product variant is in a cart then that product is made unavailable on the sales
  // channel used by the storefront, then the line item remains in the cart with an associated
  // variant of `null`. Shopify checkout will give an "out of stock" error in this case. This
  // method can be used to remove such line items from the cart.
  async _clearItemsWithNullVariants() {
    if (!this.checkout) {
      return
    }

    const idsToRemove = this.checkout.lineItems
      .filter(item => !item.variant)
      .map(item => item.id)

    if (idsToRemove.length > 0) {
      console.warn("removing line items due to missing variant", idsToRemove)
      this.removeLineItems(idsToRemove)
    }
  }

  async _createCheckout() {
    const buyClient = await createBuyClient()
    const checkout = await buyClient.checkout.create()

    persistCheckoutId(checkout.id)

    runInAction(() => {
      this.checkout = checkout
    })

    if (this.rootStore.customerStore.token) {
      // intentionally not awaiting response
      this.customerAssociate(this.rootStore.customerStore.token)
    }
  }

  // Convenience function for use when modifying the checkout. Must be passed an async function.
  // Performs the following:
  //
  //   * Creates a Shopify checkout before calling callback if one is not already present.
  //   * Sets/unsets the loading state.
  //   * Passes buyClient to callback.
  //   * Stores the return value of the callback to this.checkout and also returns it.
  async _updateCheckout(callback) {
    runInAction(() => {
      this.isLoading = true
    })

    try {
      const buyClient = await createBuyClient()

      if (!this.checkout) {
        await this._createCheckout()
      }

      const checkoutFromCallback = await callback({ buyClient })

      runInAction(() => {
        this.checkout = checkoutFromCallback
      })

      return checkoutFromCallback
    } finally {
      runInAction(() => {
        this.isLoading = false
      })
    }
  }

  toJS() {
    const { rootStore, ...others } = this
    return toJS(others)
  }
}
