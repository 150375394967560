import { makeAutoObservable, toJS } from "mobx"

export default class FlashStore {
  message

  constructor() {
    makeAutoObservable(this)
  }

  clear() {
    this.message = null
  }

  hydrateClient() {
    this.message = window.localStorage.getItem("flashStoreMessage")
    window.localStorage.removeItem("flashStoreMessage")
  }

  show(message) {
    this.message = message
  }

  showOnReload(message) {
    window.localStorage.setItem("flashStoreMessage", message)
  }

  toJS() {
    return toJS(this)
  }
}
