export const disablePreview = () => {
  window.localStorage.removeItem("enableSanityPreview")
  window.location.reload()
}

export const isPreviewEnabled = () =>
  window.localStorage?.getItem("enableSanityPreview") === "true"

export const loadPreviewDeps = async () => {
  const [sanity, previewable] = await Promise.all([
    import("@sanity/client" /* webpackChunkName: "sanity" */),
    import("./sanity-previewable" /* webpackChunkName: "sanity" */),
  ])

  const createSanityClient = (sanityConfig = {}) =>
    sanity.default({
      ...sanityConfig,
      apiVersion: "2021-03-25",
      useCdn: false,
      withCredentials: true,
    })

  return {
    createSanityClient,
    fetchPreview: previewable.fetchPreview,
  }
}
