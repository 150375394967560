import PropTypes from "prop-types"
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CMSContext from "./CMSContext"
import useGroqResults from "../../hooks/useGroqResults"

const CMSContextProvider = ({ children }) => {
  const {
    cmsKeyValue: { valueJSON },
  } = useStaticQuery(graphql`
    {
      cmsKeyValue(id: { eq: "cmsContext" }) {
        valueJSON
      }
    }
  `)

  const results = useGroqResults(JSON.parse(valueJSON))
  const value = results.reduce((acc, doc) => ({ ...acc, [doc._type]: doc }), {})

  return <CMSContext.Provider value={value}>{children}</CMSContext.Provider>
}

CMSContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CMSContextProvider
