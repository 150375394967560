import { makeAutoObservable, toJS } from "mobx"

export default class ModalStore {
  activeModal = null
  modalData = null

  constructor() {
    makeAutoObservable(this)
  }

  hideModal() {
    this.activeModal = null
    this.modalData = null
  }

  showBookingModal() {
    this.activeModal = "mindbody"
    this.modalData = {}
  }

  showBackInStockModal({ product, variant }) {
    this.activeModal = "backInStock"
    this.modalData = { product, variant }
  }

  showShopPayInstallmentsModal(variant) {
    this.activeModal = "shopPayInstallments"
    this.modalData = { variant }
  }

  toJS() {
    return toJS(this)
  }
}
