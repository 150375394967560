import { pick } from "lodash"
import { toLegacyId } from "../shopify/shopifyUtil"
import { push } from "./push"

const brand = "House of Athlete"

const selectedOptionsToMap = selectedOptions =>
  selectedOptions.reduce(
    (acc, option) => ({ ...acc, [option.name.toLowerCase()]: option.value }),
    {}
  )

export const trackAddToCart = cart => {
  // https://github.com/house-of-athlete/hoa_gatsby/pull/65#issuecomment-875058682
  push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "USD",
      add: {
        products: cart.lineItems.map(lineItem => ({
          ...selectedOptionsToMap(lineItem.variant.selectedOptions),
          brand,
          compareAtPrice: Number(lineItem.variant.compareAtPrice || 0),
          id: Number(toLegacyId(lineItem.variant.id)),
          name: lineItem.variant.product.handle,
          price: Number(lineItem.variant.price),
          productId: Number(toLegacyId(lineItem.variant.product.id)),
          variantId: Number(toLegacyId(lineItem.variant.id)),
          ...pick(lineItem, ["quantity"]),
        })),
      },
    },
  })
}

export const trackCollectionView = collection => {
  // https://github.com/house-of-athlete/hoa_gatsby/pull/65#issuecomment-875026260
  push({
    event: "collectionView",
    ecommerce: {
      actionField: { list: "Collection Page" },
      currencyCode: "USD",
      impressions: collection.products.map(({ handle, title }, position) => ({
        handle,
        name: title,
        position,
      })),
    },
    ...pick(collection, ["handle", "id", "title"]),
  })
}

export const trackProductDetailView = ({ product, variant }) => {
  push({
    event: "productDetailView",
    ecommerce: {
      currencyCode: "USD",
      detail: {
        products: [
          {
            ...selectedOptionsToMap(variant.selectedOptions),
            brand,
            compareAtPrice: Number(variant.compareAtPrice || 0),
            featImg: product.plpImage?.url,
            id: Number(variant.legacyResourceId),
            inventoryCount: variant.quantityAvailable,
            name: product.title,
            price: Number(variant.price),
            productId: Number(product.legacyResourceId),
            variantId: Number(variant.legacyResourceId),
            ...pick(product, ["handle"]),
          },
        ],
      },
    },
  })
}

export const trackVisitor = () => {
  push({ visitorType: "Guest", customer: false })

  // TODO when authentication is implemented, we'll need to send user info; see
  // https://github.com/house-of-athlete/hoa_gatsby/pull/65#issuecomment-875025487
}

export const trackPageType = pageType => {
  push({ pageType })
}
