import { isNil } from "lodash"

export const applyRechargeDiscount = (
  price,
  { discountAmount, discountType }
) => {
  if (isNil(price)) {
    return price
  }

  switch (discountType) {
    case "percentage":
      return Number(price) * 0.01 * (100 - discountAmount)

    default:
      throw new Error(`unknown discountType: ${discountType}`)
  }
}

export const rechargeDiscountDescription = ({
  discountAmount,
  discountType,
}) => {
  switch (discountType) {
    case "percentage":
      return `${discountAmount}%`

    default:
      throw new Error(`unknown discountType: ${discountType}`)
  }
}
