import { useEffect, useState } from "react"
import { isPreviewEnabled, loadPreviewDeps } from "../lib/livePreview"

const useGroqResults = queryObject => {
  const { prefetchedResults, sanityConfig } = queryObject
  const [results, setResults] = useState(prefetchedResults)

  useEffect(async () => {
    if (isPreviewEnabled()) {
      const { createSanityClient, fetchPreview } = await loadPreviewDeps()

      const newResults = await fetchPreview(
        createSanityClient(sanityConfig),
        queryObject
      )

      setResults(newResults)
    }
  }, [])

  return results
}

export default useGroqResults
