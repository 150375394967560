const rootUrl = process.env.GATSBY_HOA_SERVER_URL

export const createCheckout = async shopifyCheckout => {
  const resp = await fetch(`${rootUrl}/api/public/checkouts`, {
    body: JSON.stringify(shopifyCheckout),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  })

  return await resp.json()
}
