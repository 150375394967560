let createClientPromise = null

const createShopifyClient = () => {
  if (!createClientPromise) {
    createClientPromise = import(
      "./ShopifyClient" /* webpackChunkName: "shopifyClient" */
    ).then(
      ({ default: ShopifyClient }) =>
        new ShopifyClient({
          domain: process.env.GATSBY_SHOPIFY_DOMAIN,
          storefrontAccessToken:
            process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        })
    )
  }

  return createClientPromise
}

export default createShopifyClient
