import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import CMSContextProvider from "../../components/context/CMSContextProvider"
import StoreContext from "../../components/context/StoreContext"
import { CMSLinkConfigProvider } from "../../vendor/hoa/links"
import { RichTextConfigProvider } from "../../vendor/hoa/rich_text"
import internalLinkPath from "../internalLinkPath"

const createWrapRootElement = store => {
  const getInternalLink = document => [Link, { to: internalLinkPath(document) }]

  const performAction = action => {
    switch (action) {
      case "bookingDialog":
        store.modalStore.showBookingModal()
        break

      default:
        break
    }
  }

  const WrapWithProviders = ({ element }) => (
    <StoreContext.Provider value={store}>
      <CMSContextProvider>
        <RichTextConfigProvider
          sanityConfig={{
            dataset: process.env.GATSBY_SANITY_DATASET,
            projectId: process.env.GATSBY_SANITY_PROJECT_ID,
          }}
        >
          <CMSLinkConfigProvider
            getInternalLink={getInternalLink}
            performAction={performAction}
          >
            {element}
          </CMSLinkConfigProvider>
        </RichTextConfigProvider>
      </CMSContextProvider>
    </StoreContext.Provider>
  )

  WrapWithProviders.propTypes = {
    element: PropTypes.node.isRequired,
  }

  return WrapWithProviders
}

export default createWrapRootElement
