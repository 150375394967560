const getGALinkerParam = () => {
  try {
    return window.ga.getAll()[0].get("linkerParam") || ""
  } catch (error) {
    console.error(error)
    return ""
  }
}

export default getGALinkerParam
