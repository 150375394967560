import { makeAutoObservable, toJS } from "mobx"

export default class OverlayStore {
  expandedItem = null // "cart" or "mobileNav"

  constructor() {
    makeAutoObservable(this)
  }

  hide() {
    this.expandedItem = null
  }

  get isCartExpanded() {
    return this.expandedItem === "cart"
  }

  get isMobileNavExpanded() {
    return this.expandedItem === "mobileNav"
  }

  showCart() {
    this.expandedItem = "cart"
  }

  showMobileNav() {
    this.expandedItem = "mobileNav"
  }

  toJS() {
    return toJS(this)
  }
}
