import { isNumber, isString, toString } from "lodash"

const gidPrefix = "gid://shopify/"

/**
 * Converts an ID in any format to a string containing the product ID integer.
 */
export const toLegacyId = id => {
  if (isNumber(id)) {
    return toString(id)
  } else if (isString(id)) {
    const decodedId = base64Decode(id)

    if (decodedId?.startsWith(gidPrefix)) {
      return gidToId(decodedId)
    } else if (id.startsWith(gidPrefix)) {
      return gidToId(id)
    } else if (id.match(/^[0-9]+$/)) {
      return id
    }
  }

  throw new Error(`invalid id format: ${id}`)
}

const gidToId = string => string.match(/^gid:\/\/shopify\/[A-Za-z]+\/(.+)$/)[1]

const base64Decode = string => {
  // Shopify storefront API returns GIDs that are base 64 encoded
  try {
    return atob(string)
  } catch {
    return null
  }
}
