const internalLinkPath = doc => {
  switch (doc._type) {
    case "customInternalLink":
      return doc.link

    default:
      return doc.path.current
  }
}

export default internalLinkPath
